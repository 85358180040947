<template>
  <div class="menu-link-drop-down">
    <div class="menu-link">
      <div
        class="globale-item"
        :class="{
          'globale-item-active': computedActiveLink
        }"
        @click="showList = !showList"
      >
        <span> {{ title }}</span>
        <font-awesome-icon icon="caret-down" v-if="showList == false" />
        <font-awesome-icon icon="caret-up" v-if="showList == true" />
      </div>
    </div>
    <template v-if="showList == true">
      <div
        class="menu-link resize-width-menu-item"
        v-for="(item, index) in items"
        :key="title + 'item' + index"
      >
        <router-link
          class="menu-item"
          :to="item.href"
          v-if="computedCoesCheck(item.code)"
        >
          {{ item.name }}
        </router-link>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    title: { required: true },
    items: { required: true }
  },
  data() {
    return { showList: false };
  },
  computed: {
    ...mapGetters(['checkPermission']),
    computedActiveLink() {
      let tab = this.items.filter(item => item.href == this.$route.path);
      if (tab.length > 0) {
        return true;
      }
      return false;
    },
    computedSubActiveLink() {
      return function(item) {
        return item.href == this.$route.path;
      };
    },
    computedCoesCheck() {
      return function(coeds) {
        if (typeof coeds == 'string') {
          return this.checkPermission(coeds);
        } else {
          coeds.forEach(element => {
            if (!this.checkPermission(element)) {
              return false;
            }
          });

          return true;
        }
      };
    }
  },
  mounted() {
    if (this.computedActiveLink) {
      this.showList = true;
    }
  }
};
</script>
<style lang="scss">
.menu-link-drop-down {
  border-radius: 25px;
  margin: 1px 0px;
  .retour-style {
    span {
      margin-left: 10px;
    }
  }
  .menu-link {
    font-size: 12px;
    .flesh-icon {
      width: 23px;
    }
    a.menu-item,
    .globale-item {
      justify-content: space-between;
      border-radius: 21px 0px 0px 21px;
      display: block;
      font-size: 13px;
      font-weight: 500;
      color: #495057;
      margin: 4px 0;
      padding: 6px 10px;
      text-decoration: none;
      background-color: #ffffff;
      font-family: 'Montserrat', sans-serif;
      &.router-link-exact-active {
        color: #fff;
        background-color: #8d8cb7;
      }
      &:hover {
        color: #fff;
        background-color: #9799d6;
      }
      span {
        margin-top: 2px;
      }
      &.globale-item-active {
        color: #fff;
        background-color: #4d4bac;
      }
    }
    .globale-item {
      display: flex;
      .svg-inline--fa {
        font-size: 25px;
        height: 19px;
      }
    }
  }
  .resize-width-menu-item {
    margin-left: 14px;
    a.menu-item {
      font-size: 12px;
    }
  }
}
</style>
